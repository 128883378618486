import { MDXProvider } from "@mdx-js/react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as React from "react"
import Layout from "../components/Layout"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import InformationBox from "../components/InformationBox"
const shortcodes = { InformationBox }

const LandingPageTemplate = ({ data, pageContext }) => {
  const { body, frontmatter } = data?.landing
  const linksForPages = data?.linksForPages?.nodes
  // const sourceInstanceName = data?.linksForPages?.nodes?.sourceInstanceName
  const {
    breadcrumb: { crumbs },
  } = pageContext
  crumbs?.map((crumb: any) => {
    crumb.crumbLabel = crumb?.crumbLabel?.replaceAll("-", " ")
  })
  if (frontmatter?.links) {
    JSON.parse(frontmatter?.links)?.map((linksFromFrontmatter: any) => {
      let isAlreadyAvailable = false
      linksForPages?.map((linkFromContent: any) => {
        if (
          linksFromFrontmatter?.childMdx?.slug ==
          linkFromContent?.childMdx?.slug
        ) {
          isAlreadyAvailable = true
        }
      })
      if (!isAlreadyAvailable) {
        linksForPages.push(linksFromFrontmatter)
      }
    })
  }

  // const relatedArticles = data.relatedArticles.nodes
  console.log("LandingPageTemplate data: ", data)
  // console.log("LandingPageTemplate linksForPages: ", linksForPages)
  return (
    <Layout>
      <Breadcrumb crumbs={crumbs} crumbSeparator=" > " />
      <MDXProvider components={shortcodes}>
        <MDXRenderer>{body}</MDXRenderer>
        {linksForPages?.length > 0 && (
          <div className="faq-card landing-page-card">
            <div className="d-flex row">
              {linksForPages?.map(
                (list: any) =>
                  list?.childMdx?.slug && (
                    <div className="column">
                      <div className="content">
                        <div className="d-flex align-items-center">
                          <img
                            src={frontmatter?.icon?.publicURL}
                            alt={frontmatter?.iconAlt}
                          />
                          <Link
                            to={`/${list?.sourceInstanceName}/${list?.childMdx?.slug}`}
                          >
                            {list?.childMdx?.frontmatter?.title}
                          </Link>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        )}
      </MDXProvider>
    </Layout>
  )
}
export default LandingPageTemplate

export const query = graphql`
  query landing($id: String, $sourceInstanceName: String) {
    landing: mdx(id: { eq: $id }) {
      frontmatter {
        title
        date
        icon {
          publicURL
        }
        iconAlt
        links
      }
      body
    }
    linksForPages: allFile(
      filter: {
        sourceInstanceName: { eq: $sourceInstanceName }
        relativePath: { ne: "index.mdx" }
        name: { eq: "index" }
      }
      sort: { order: ASC, fields: childrenMdx___frontmatter___order }
    ) {
      nodes {
        childMdx {
          frontmatter {
            title
            date
          }
          slug
        }
        sourceInstanceName
      }
    }
  }
`
