import * as React from "react"
import PropTypes from "prop-types"
const InformationBox = props => (
  <blockquote class={props?.type}>
    <p>{props?.children}</p>
  </blockquote>
)
InformationBox.propTypes = {
  type: PropTypes.string,
}

export default InformationBox
